.ckeditorWhite {
	color: @White;

	.cke_editable & {
		color: @PrimaryFontColor;
	}
}

.ckeditorGreen {
	color: @SecondaryCiColor;
}

.ckeditorBlue {
	color: @PrimaryFontColor;
}